<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_hr.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="20">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/hr?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdTitle}}</div>
              <div class="fr listHdGrop"><a href="/">首页</a> · <a :href="`${apiName}/hr`">{{slideHd.title}}</a> · <span class="txt">{{hdTitle}}</span> </div>
            </div>
            <div class="listContainer">
              <!-- <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a class="clearfloat" href="/detail">
                    <div class="time fr">
                      <h3>{{item.time.slice(5,10)}}</h3>
                      <p>{{item.time.slice(0,4)}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div> -->
              
              <detail-page ref="listPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
              <list-img ref="listPage" :gropId="gropId" v-if="pageType == 3"></list-img>
              <list-video ref="listPage" :gropId="gropId" v-if="pageType == 4"></list-video>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import listPage from '@/components/list.vue'
import detailPage from '@/components/detail.vue'
import listImg from '@/components/listImg.vue'
import listVideo from '@/components/listVideo.vue'
export default {
  components: {
    listPage,
    detailPage,
    listImg,
    listVideo
  },
  name: 'list',
  data(){
    return {
      pageType: 0,
      gropId: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '人力资源',
        sub: 'HUMAN RESOURCES',
      },
      slideList:[
        {
          title: '招贤纳士',
          id: '359',
          type: 2
        },
        {
          title: '人才引进',
          id: '360',
          type: 2
        },
        {
          title: '下载中心',
          id: '361',
          type: 2
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
        {
          title: '集团公司机关纪委认真学习贯彻党的十九届六中全会精神',
          time: '2021-10-12',
        },
      ]
    }
  },
  mounted(){
    var _this = this
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    this.$nextTick(() => {
      _this.gropId = _this.slideList[_this.active].id
      _this.pageType = _this.slideList[_this.active].type
    })
    _this.hdTitle = _this.slideList[_this.active].title
    _this.pageCont = _this.page[_this.active]
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    }
  }
}
</script>